import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getMetaSiteId } from '../../common/store/instance-values/instance-values-selectors';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import { setViewMode } from '../../common/store/view-mode/view-mode-actions';
import { setUserAgent } from '../../common/store/user-agent/user-agent-actions';
import { setLocale } from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { fetchPostListPosts } from '../actions/fetch-post-list-posts';
import { initWixData } from '../../common/actions/init-wix-data';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import fetchCategories from '../../common/store/categories/fetch-categories';

export {
  initializeActions,
  initializePromisifiedActions,
  initUserDependentStoreBaseData,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  appParams,
  store,
  language,
  platformAPIs,
  config,
  sectionUrl,
  bundleName,
  fedopsAppName,
  translationsName,
}) {
  const instanceId = appParams.instanceId;
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();
  const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : '';

  store.dispatch(initialiseInstanceValues(appParams.instance));

  const metaSiteId = getMetaSiteId(store.getState());

  await store.dispatch(initWixData(viewMode, 'categoryId', config));
  await store.dispatch(fetchCategories(metaSiteId));

  await Promise.all([
    store.dispatch(fetchExperiments(metaSiteId)).then(() => store.dispatch(fetchTopology(instanceId))),
    store.dispatch(fetchTranslations(language, translationsName)),
    store.dispatch(setViewMode(viewMode)),
    store.dispatch(setUserAgent({ source: userAgent })),
    store.dispatch(setLocale(language)),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(fetchPostListPosts(1, getPostListWidgetPageSize(store.getState()))),
  ]);

  await store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber }));
  await store.dispatch(setAppConfig({ sectionUrl, bundleName, fedopsAppName }));
}
