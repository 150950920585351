import uri from 'urijs';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { POST_LIST_SETTINGS_PARAMS } from '@wix/communities-blog-client-common/dist/src/constants/post-list-widget-constants';
import { getAppSettingsNumber, getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { isExcludePostContentSupported } from '../../common/selectors/layout-selectors';
import { SECTION_POST_LIST } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { buildPaginationRequestParams, getTotalResults } from '../../common/services/pagination';
import setPosts from '../../common/actions/set-posts';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { getWixDataCategoryId } from '@wix/communities-blog-client-common/dist/src/store/wix-data/get-wix-data';
import { setPostCount } from './set-posts-count';
import { getCategories } from '@wix/communities-blog-client-common/dist/src/store/categories/categories-selectors';

export const FETCH_POST_LIST_POSTS_SUCCESS = 'fetchPostListPosts/SUCCESS';

const fetchPostListPostsSuccess = createAction(FETCH_POST_LIST_POSTS_SUCCESS);

export const fetchPostListPosts = (page = 1, _pageSize) => (dispatch, getState, { request }) => {
  const state = getState();
  const pageSize =
    _pageSize ||
    getAppSettingsNumber(
      state,
      POST_LIST_SETTINGS_PARAMS.entityCount.wixParam,
      POST_LIST_SETTINGS_PARAMS.entityCount.defaultValue,
    );
  const featuredOnly = getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath });
  const widgetCategoryId = getWixDataCategoryId(state);
  const category = getCategories(state).find(category => category.id === widgetCategoryId);
  const categoryId = category && category._id;
  const params = buildPaginationRequestParams(page, pageSize);

  dispatch(setIsLoading('postListPosts', undefined, true));

  const promise = request(
    uri('/_api/posts').query({
      ...params,
      pinnedFirst: true,
      featuredOnly,
      excludeContent: isExcludePostContentSupported(state, SECTION_POST_LIST),
      categoryIds: categoryId ? [categoryId] : [],
    }),
    { parseHeaders: true },
  );

  return promise
    .then(({ body, headers }) => {
      dispatch(setPosts(body));
      dispatch(setPostCount(getTotalResults(headers)));
      return body;
    })
    .then(posts => dispatch(fetchPostListPostsSuccess(posts)))
    .then(() => dispatch(setIsLoading('postListPosts', undefined, false)))
    .catch(() => dispatch(setIsLoading('postListPosts', undefined, false)));
};

export const fetchPostListPostsPromisified = createPromisifiedAction(
  fetchPostListPosts,
  () => null,
  response => response.status,
);
