import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

export const FETCH_TAGS_REQUEST = 'tags/FETCH_REQUEST';
export const FETCH_TAGS_SUCCESS = 'tags/FETCH_SUCCESS';
export const FETCH_TAGS_FAILURE = 'tags/FETCH_FAILURE';

export const fetchTagsRequest = createAction(FETCH_TAGS_REQUEST);
export const fetchTagsSuccess = createAction(FETCH_TAGS_SUCCESS);
export const fetchTagsFailure = createAction(FETCH_TAGS_FAILURE);

const fetchTags = () => (dispatch, _getState, { request }) => {
  dispatch(fetchTagsRequest());

  return request('/v2/tags/query', { method: 'POST' })
    .then(({ tags }) => dispatch(fetchTagsSuccess(tags)))
    .catch(() => dispatch(fetchTagsFailure()));
};

export default fetchTags;
