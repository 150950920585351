import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';

export const SET_POSTS = 'posts/SET';

const setNormalizedPosts = createAction(SET_POSTS);

const setPosts = (posts = []) => (dispatch, getState) => {
  const normalizedPosts = normalizePosts({ posts, blogCategoryIds: getCategoryIds(getState()) });
  dispatch(setNormalizedPosts(normalizedPosts));
  return normalizedPosts;
};

export default setPosts;
