import { FETCH_TAGS_SUCCESS } from './fetch-tags';

const tags = (state = [], { type, payload } = {}) => {
  switch (type) {
    case FETCH_TAGS_SUCCESS:
      return payload;

    default:
      return state;
  }
};

export default tags;
