export const FETCH_TAG_REQUEST = 'tag/FETCH_REQUEST';
export const FETCH_TAG_SUCCESS = 'tag/FETCH_SUCCESS';
export const FETCH_TAG_FAILURE = 'tag/FETCH_FAILURE';

export const fetchTagSuccess = payload => ({ type: FETCH_TAG_SUCCESS, payload });

export function fetchTag(tagSlug) {
  return (dispatch, getState, { request }) => {
    return request
      .post('/v2/tags/query', {
        filter: {
          slug: {
            $eq: tagSlug,
          },
        },
      })
      .then(({ tags }) => {
        if (tags.length) {
          dispatch(fetchTagSuccess(tags[0]));

          return tags[0];
        }

        return Promise.reject({ status: 404 });
      });
  };
}
