import { get, isEmpty, omitBy, isNil } from 'lodash';
import { BLOG_APP_ID, BLOG_POST_PAGE_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { PAID_PLANS_APP_ID } from '../../constants/paid-plans';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

export const NAVIGATE_TO_PAID_PLANS = 'app/NAVIGATE_TO_PAID_PLANS';

const navigateToPaidPlansAction = createAction(NAVIGATE_TO_PAID_PLANS);

const getPaidPlansSectionUrl = wixCodeApi => {
  const paidPlansPage = wixCodeApi.site.getSiteStructure().pages.find(page => page.applicationId === PAID_PLANS_APP_ID);
  return get(paidPlansPage, 'url', '');
};

export const navigateToPaidPlans = (planIds, returnUrl = '', titleText, contentText, buttonText) => (
  dispatch,
  getState,
  { wixCodeApi },
) => {
  const queryParams = {};
  if (returnUrl) {
    queryParams.navigateToSectionProps = btoa(
      JSON.stringify({
        sectionId: BLOG_POST_PAGE_SECTION_ID,
        appDefinitionId: BLOG_APP_ID,
        state: encodeURI(returnUrl),
        shouldRefreshIframe: true,
      }),
    );
  }
  if (planIds) {
    queryParams.planIds = planIds.join(',');
  }
  const content = omitBy({ titleText, contentText, buttonText }, isNil);
  if (!isEmpty(content)) {
    try {
      queryParams.verticalStatusContent = btoa(JSON.stringify(content));
    } catch (_) {}
  }
  const sectionUrl = getPaidPlansSectionUrl(wixCodeApi);
  const path = isEmpty(queryParams) ? '' : `?appSectionParams=${encodeURIComponent(JSON.stringify(queryParams))}`;
  dispatch(navigateToPaidPlansAction({ path }));
  return wixCodeApi.location.to(`${sectionUrl}${path}`);
};
