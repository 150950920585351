import contentMigration from '@wix/communities-blog-client-common/dist/src/reducers/content-migration-status';
import { appLoaded } from './store/app-loaded/app-loaded-reducer';
import { appSettings } from './store/app-settings/app-settings-reducer';
import { basicParams } from './store/basic-params/basic-params-reducer';
import { communitiesContext } from './store/communities-context/communities-context-reducer';
import { currentUser } from './store/current-user/current-user-reducer';
import { debugState } from './store/debug-state/debug-state-reducer';
import { fastFormSubmit } from './components/fast-form/store/fast-form-reducer';
import { instanceValues } from './store/instance-values/instance-values-reducer';
import { maNavigation } from './store/ma-navigation/ma-navigation-reducer';
import { messageReducer } from './messages/framework/initialize-store';
import { modalReducer } from './modals/framework/initialize-store';
import { promisifiedActionsResults } from './actions-promisifier/store/promisified-actions-reducer';
import { routerReducer } from './router';
import { savedAction } from './store/saved-action/saved-action-reducer';
import { translations } from './store/translations/translations-reducer';
import activeTab from './store/tabs/active-tab-reducer';
import appConfig from './store/app-config/app-config-reducer';
import appData from './store/app-data/app-data-reducer';
import auth from './store/auth/auth-reducer';
import categories from './store/categories/categories-reducer';
import tags from './store/tags/tags-reducer';
import commentEdit from './store/comments/edited-comment-id-reducer';
import commentLikes from './store/comment-likes/comment-likes-reducer';
import comments from './store/comments/comments-reducer';
import editorConfig from './store/editor-config/editor-config-reducer';
import experiments from './store/experiments/experiments-reducer';
import header from './store/header/header-reducer';
import headerWidget from './store/header-widget/header-widget-reducer';
import isAppLoaded from './store/is-app-loaded/is-app-loaded-reducer';
import isLoaded from './store/is-loaded/is-loaded-reducer';
import isLoading from './store/is-loading/is-loading-reducer';
import isSaving from './store/is-saving/is-saving-reducer';
import locale from './store/locale/locale-reducer';
import members from './store/members/members-reducer';
import message from './store/message/message-reducer';
import pagination from './store/pagination/pagination-reducer';
import postCounters from './store/post-counters/post-counters-reducer';
import postLikes from './store/post-likes/post-likes-reducer';
import postSubscription from './store/post-subscription/post-subscription-reducer';
import posts from './store/posts/posts-reducer';
import search from '../search/reducers/search-reducer';
import searchInput from './store/search-input/search-input-reducer';
import siteInfo from './store/site-info/site-info-reducer';
import siteProperties from './store/site-properties/site-properties-reducer';
import topology from './store/topology/topology-reducer';
import userAgent from './store/user-agent/user-agent-reducer';
import users from './store/users/users-reducer';
import userStats from './store/user-stats/user-stats-reducer';
import viewMode from './store/view-mode/view-mode-reducer';
import writers from './store/writers/writers-reducer';
import paywall from './store/paywall/paywall-reducer';

export default {
  ...messageReducer,
  ...modalReducer,
  ...routerReducer,
  activeTab,
  appConfig,
  appData,
  appLoaded,
  appSettings,
  auth,
  basicParams,
  categories,
  commentEdit,
  commentLikes,
  comments,
  communitiesContext,
  contentMigration,
  currentUser,
  debugState,
  editorConfig,
  experiments,
  fastFormSubmit,
  header,
  headerWidget,
  instanceValues,
  isAppLoaded,
  isLoaded,
  isLoading,
  isSaving,
  locale,
  maNavigation,
  members,
  message,
  pagination,
  posts,
  postCounters,
  postLikes,
  postSubscription,
  promisifiedActionsResults,
  savedAction,
  search,
  searchInput,
  siteInfo,
  siteProperties,
  topology,
  translations,
  userAgent,
  users,
  userStats,
  viewMode,
  writers,
  paywall,
  tags,
};
