import { omit, pick } from 'lodash';
import { FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import { FETCH_POST_SUCCESS } from '../../actions/fetch-post';
import { FETCH_POST_METADATA_SUCCESS } from '../../actions/fetch-post-metadata';
import { FETCH_USER_POSTS_SUCCESS } from '../../actions/fetch-posts-by-user';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_POSTS_SUCCESS } from '../../actions/fetch-posts';
import { FETCH_RECENT_POSTS_SUCCESS } from '../../../post-page/actions/fetch-recent-posts';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { FETCH_SEARCH_SUCCESS } from '../../../search/actions/fetch-search';
import {
  INCREMENT_POST_LIKE_COUNT_REQUEST,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
  INCREMENT_POST_LIKE_COUNT_FAILURE,
} from './post-likes-actions';
import { UPDATE_POST_COUNTERS } from '../post-counters/post-counters-actions';
import { FETCH_POST_LIST_POSTS_SUCCESS } from '../../../post-list-widget/actions/fetch-post-list-posts';

export default function postLikes(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_POST_SUCCESS:
      return post(state, payload.post);
    case FETCH_POST_METADATA_SUCCESS:
      return post(state, payload);
    case FETCH_USER_POSTS_SUCCESS:
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_POSTS_SUCCESS:
    case FETCH_POST_LIST_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_ARCHIVE_SUCCESS:
      return posts(state, payload);
    case FETCH_SEARCH_SUCCESS:
      return posts(state, payload.posts);
    case INCREMENT_POST_LIKE_COUNT_REQUEST:
      return likeRequest(state, payload._id);
    case INCREMENT_POST_LIKE_COUNT_SUCCESS:
      return likeSuccess(state, payload._id);
    case INCREMENT_POST_LIKE_COUNT_FAILURE:
      return likeFailure(state, payload._id);
    case UPDATE_POST_COUNTERS:
      return postCounters(state, payload);
    default:
      return state;
  }
}

function post(state, post) {
  return { ...state, [post._id]: getLikes(post) };
}

function posts(state, posts) {
  return {
    ...state,
    ...posts.reduce((result, post) => {
      result[post._id] = getLikes(post);
      return result;
    }, {}),
  };
}

function likeRequest(state, _id) {
  const post = state[_id];
  if (!post) {
    return state;
  }

  return {
    ...state,
    [_id]: {
      isLiked: !post.isLiked,
      likeCount: post.likeCount + (post.isLiked ? -1 : 1),
      isLikeInProgress: true,
    },
  };
}

function likeSuccess(state, _id) {
  const post = state[_id];
  if (!post) {
    return state;
  }

  return {
    ...state,
    [_id]: omit(post, 'isLikeInProgress'),
  };
}

function likeFailure(state, _id) {
  const post = state[_id];
  if (!post) {
    return state;
  }

  return {
    ...state,
    [_id]: {
      isLiked: !post.isLiked,
      likeCount: post.likeCount + (post.isLiked ? -1 : 1),
    },
  };
}

function postCounters(state, { _id, likeCount }) {
  const post = state[_id];

  if (!post || post.likeCount === likeCount) {
    return state;
  }

  return { ...state, [_id]: { ...post, likeCount } };
}

function getLikes(post) {
  return pick(post, ['likeCount', 'isLiked']);
}
